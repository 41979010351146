<template>
  <form
    class="add-form"
    ref="profile_form"
    @submit.prevent="handleSubmit"
    @keydown.enter.prevent="() => {}"
  >
    <div class="form-wrapper">
      <image-selector
        :label="$t('COMMON.PICTURE')"
        :defaultImage="resellerProduct.picture"
        ressource_name="resellerProducts"
        :ressource_id="resellerProduct.id ? resellerProduct.id : 0"
        field="picture"
        @imageChanged="
          (file_url) => {
            resellerProduct.picture = file_url;
            onFormChanged();
          }
        "
      />
      <validation-error :errors="apiValidationErrors.attachment" />
    </div>

    <div class="form-wrapper">
      <gallery-selector
        :label="$t('COMMON.GALLERY')"
        :defaultGallery="resellerProduct.gallery"
        ressource_name="resellerProducts"
        :ressource_id="resellerProduct.id ? resellerProduct.id : 0"
        field="gallery"
        @galleryChanged="
          (gallery_urls) => {
            resellerProduct.gallery = gallery_urls;
            onFormChanged();
          }
        "
      />
      <validation-error :errors="apiValidationErrors.attachment" />
    </div>

    <div
      class="form-wrapper"
      v-if="$currentUserCan($permissions.PERM_VIEW_ANY_RESELLERS)"
    >
      <base-input
        :label="`${$t('COMMON.RESELLER')} (*)`"
        :placeholder="$t('COMMON.RESELLER')"
      >
        <reseller-selector
          :reseller="resellerProduct.reseller.id"
          :filterable="true"
          :showAll="false"
          @resellerChanged="
            (resellerId) => {
              resellerProduct.reseller.id = resellerId;
              onFormChanged();
            }
          "
        />
      </base-input>
      <validation-error :errors="apiValidationErrors.reseller" />
    </div>

    <div class="form-wrapper">
      <base-input
        :label="`${$t('COMMON.NAME')} (*)`"
        :placeholder="$t('COMMON.NAME')"
        v-model="resellerProduct.name"
        @change="onFormChanged()"
      />
      <validation-error :errors="apiValidationErrors.name" />
    </div>

    <div class="form-wrapper full">
      <base-input
        :label="$t('COMMON.EXCERPT')"
        :placeholder="$t('COMMON.EXCERPT')"
      >
        <html-editor
          v-model="resellerProduct.excerpt"
          @change="onFormChanged()"
        >
        </html-editor>
      </base-input>
      <validation-error :errors="apiValidationErrors.excerpt" />
    </div>

    <div class="form-wrapper full">
      <base-input
        :label="`${$t('COMMON.PRICE')} (*)`"
        :placeholder="$t('COMMON.PRICE')"
        v-model="resellerProduct.price"
        @change="onFormChanged()"
      />
      <validation-error :errors="apiValidationErrors.price" />
    </div>

    <div class="form-wrapper full">
      <taxes-selector
        :label="$t('COMMON.TAXES')"
        :taxes="resellerProduct.taxes"
        @taxesChanged="
          (taxes) => {
            resellerProduct.taxes = taxes;
            onFormChanged();
          }
        "
      />
      <validation-error :errors="apiValidationErrors.taxes" />
    </div>

    <div class="submit-wrapper">
      <base-button
        type="button"
        class="btn btn-sm elite-submit"
        native-type="submit"
        :disabled="loading"
      >
        <i class="fas fa-spinner fa-spin" v-if="loading" />
        {{
          resellerProduct.id
            ? $t("RESELLER_PRODUCTS.EDIT_RESELLER_PRODUCT")
            : $t("RESELLER_PRODUCTS.ADD_RESELLER_PRODUCT")
        }}
      </base-button>
    </div>
  </form>
</template>
<script>
import { cloneDeep } from "lodash";
import { Select, Option } from "element-ui";
import formMixin from "@/mixins/form-mixin";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import ValidationError from "@/components/ValidationError.vue";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import models from "@/constants/models";
import ResellerSelector from "@/components/ResellerSelector.vue";
import ImageSelector from "@/components/ImageSelector.vue";
import GallerySelector from "@/components/GallerySelector.vue";
import TaxesSelector from "@/components/TaxesSelector.vue";

export default {
  layout: "DashboardLayout",

  components: {
    HtmlEditor,
    BaseInput,
    ValidationError,
    ResellerSelector,
    ImageSelector,
    GallerySelector,
    TaxesSelector,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  mixins: [formMixin],

  props: ["resellerProductData", "formErrors", "loading"],

  data() {
    let resellerProductData = cloneDeep(this.resellerProductData);
    resellerProductData = this.$fillUserResellerData(resellerProductData);
    return {
      resellerProduct: resellerProductData,
      permissions: [],
      permissionsModel: {},
      models: models,
    };
  },

  created() {},

  methods: {
    async handleSubmit() {
      let resellerProductData = cloneDeep(this.resellerProduct);
      resellerProductData = this.$fillUserResellerData(resellerProductData);
      this.$emit("resellerProductSubmitted", resellerProductData);
    },

    onFormChanged() {
      this.$emit("formChanged");
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    resellerProductData(resellerProductData) {
      if (resellerProductData) {
        this.resellerProduct = {
          ...this.resellerProduct,
          ...cloneDeep(resellerProductData),
        };
      }
    },
  },
};
</script>
