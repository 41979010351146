<template>
  <div class="container-fluid">
    <reseller-product-form
      :loading="loading"
      :resellerProductData="resellerProduct"
      :formErrors="formErrors"
      @resellerProductSubmitted="handleSubmit"
      @formChanged="() => (alertLeave = true)"
    />
  </div>
</template>

<script>
import { Select, Option } from "element-ui";
import { cloneDeep } from "lodash";
import defaultTaxes from "@/constants/defaultTaxes";
import alertLeave from "@/mixins/alert-leave-mixin";
import defaultResellerProduct from "../defaultResellerProduct";
import ResellerProductForm from "../partials/ResellerProductForm.vue";

export default {
  layout: "DashboardLayout",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    ResellerProductForm,
  },

  mixins: [alertLeave],

  data() {
    const resellerProduct = cloneDeep(defaultResellerProduct);
    resellerProduct.taxes = cloneDeep(defaultTaxes);
    return {
      resellerProduct: resellerProduct,
      formErrors: null,
      loading: false,
    };
  },

  created() {},

  methods: {
    async handleSubmit(resellerProduct) {
      this.loading = true;

      const resellerProductData = cloneDeep(resellerProduct);
      delete resellerProductData.id;

      try {
        await this.$store.dispatch("resellerProducts/add", resellerProductData);
        this.$notify({
          type: "success",
          message: this.$t("RESELLER_PRODUCTS.RESELLER_PRODUCT_ADDED"),
        });
        const resellerProduct = await this.$store.getters[
          "resellerProducts/resellerProduct"
        ];
        this.$emit("onViewResellerProduct", resellerProduct, true);
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        this.loading = false;
      }
    },
  },
};
</script>
